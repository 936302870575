import React from "react";
import styled from "styled-components";

import { breakpoints } from "../styles/__variables";

interface BowProps {
  reversed?: boolean;
}

const BowDiv = styled.div<BowProps>`
  display: none;
  @media screen AND (min-width: ${`${breakpoints.xl}px`}) {
    overflow: hidden;
    display: block;
    height: 150vh;
    width: 150vw;

    ${(props) =>
      props.reversed
        ? "-webkit-transform: scaleX(-1);-moz-transform: scaleX(-1); -o-transform: scaleX(-1); transform: scaleX(-1); filter: FlipH; -ms-filter: 'FlipH';"
        : ""}
  }
`;

const Bow: React.FC<BowProps> = ({ reversed }) => {
  return (
    <BowDiv reversed={reversed}>
      <img src="/assets/gestaltungselemente/bogen.svg" />
    </BowDiv>
  );
};

export default Bow;
