import React from "react";
import Paragraph from "../Paragraph";
import List from "../List";
import Title from "../Title";
import { entwickelnListe } from "../data";
import { colors } from "../../styles/__variables";
import FriendlyWorkspaceBlock from "../FriendlyWorkspaceBlock";

const Entwickeln: React.FC = () => {
  return (
    <article>
      <Title color={colors.yellowGreen.base}>Entwickeln</Title>
      <List list={entwickelnListe} color={colors.yellowGreen.text} />
      <Paragraph color={colors.yellowGreen.text}>
        Möchten Sie Unterstützung bei der Entwicklung von HR-Konzepten wie
        Einführung eines Betrieblichen Gesundheitsmanagements oder eines
        Führungsfeedbacks, Aufbau einer systematischen Personalentwicklung oder
        eines Ideenmanagements? Ich gebe Ihren Vorstellungen mit einem Konzept
        eine Struktur oder helfe Ihnen dabei, Ihre Ideen weiter zu entwickeln.
        Dazu erarbeite ich die passenden HR-Hilfsmittel wie Wegleitungen,
        Interviewbögen, Feedbacktools oder Leitfäden.
      </Paragraph>
      <Paragraph color={colors.yellowGreen.text}>
        Mit meinem Coachingpaket «Neu in der Führungsrolle» begleite ich
        Führungspersonen während den ersten Monaten in ihrer neuen Rolle. Mein Coachingpaket «PERMA-Lead» bringt Sie und Ihre Mitarbeitenden zum Aufblühen: PERMA-Lead ist ein wissenschaftsbasierter Ansatz der positiven Psychologie und beschreibt konkrete Verhaltensweisen, wie Führungspersonen das PERMA (bzw. Faktoren, die zu einem erfüllten Leben beitragen) der Mitarbeitenden verstärken können. Mit einem Testverfahren nehmen Sie eine Standortbestimmung vor. Diese dient im Coaching als Basis für die Weiterentwicklung Ihres PERMA-Verhaltens als Führungsperson. Auch 360-Grad-Feedbacks oder Organisationskultur-Analysen sind mit dem PERMA-Modell möglich.
      </Paragraph>
      <FriendlyWorkspaceBlock />
    </article>
  );
};

export default Entwickeln;
