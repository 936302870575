import React from "react";
import Paragraph from "../Paragraph";
import List from "../List";
import Title from "../Title";
import { anpackenListe } from "../data";
import { colors } from "../../styles/__variables";

const Anpacken: React.FC = () => {
  return (
    <article>
      <Title color={colors.yellowGreen.base}>Anpacken</Title>
      {/* <List list={anpackenListe} color={colors.yellowGreen.text} /> */}
      <Paragraph color={colors.yellowGreen.text}>
        Sind Sie auf der Suche nach befristeten Ressourcen für ein bestimmtes
        Projekt? Möchte Sie gewisse HR-Aufgaben, z.B. Austrittsgespräche oder
        Rekrutierung, permanent outsourcen? Ich springe ein. Sei es, um eine
        kurzfristige Lücke zu schliessen oder Sie längerfristig zu unterstützen.
        Dank meiner breiten und langjährigen Erfahrung aus verschiedenen
        Unternehmen in der Privatwirtschaft und in öffentlich-rechtlichen
        Institutionen bin ich rasch produktiv und effizient.
      </Paragraph>
    </article>
  );
};

export default Anpacken;
