import * as React from "react";
import styled from "styled-components";

import { breakpoints, spacers } from "../styles/__variables";

const AngebotWrapperDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  padding: ${`${spacers.mobile}px`};

  > * {
    max-width: 900px;
    z-index: 3;
  }
  article {
    overflow: scroll;
  }

  @media screen AND (min-width: ${`${breakpoints.md}px`}) {
    padding: ${`${spacers.tablet}px`};
  }

  @media screen AND (min-width: ${`${breakpoints.xl}px`}) {
    margin-top: 0;
    padding: ${`${spacers.desktop}px`};
  }
  @media screen AND (min-width: ${`${breakpoints.xxl}px`}) {
    padding: ${`${spacers.desktop}px`};
  }
`;

const ContentWrapper = styled.div`
  max-width: 900px;

  margin: 0 auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow-y: scroll;
`;

const AngebotWrapper: React.FC = ({ children }) => {
  return (
    <AngebotWrapperDiv>
      <ContentWrapper>{children}</ContentWrapper>
    </AngebotWrapperDiv>
  );
};

export default AngebotWrapper;
