import * as React from "react";
import { useState } from "react";
// import Helmet from "react-helmet";
import styled from "styled-components";

import Navigation from "../components/navigation/Navigation";
import Header from "../components/navigation/Header";

import { heights, breakpoints } from "../styles/__variables";

import "normalize.css";
import "../styles/base.css";

interface AngebotLayoutProps {
  styles: {
    navColor: string;
    navColorDesktop: string;
  };
}
// import { fonts } from "../styles/variables";
// font-family: ${fonts.monospace};
const Layout = styled.div<AngebotLayoutProps>`
  display: flex;
  flex-direction: column;
  margin-top: ${`${heights.nav.mobile}px`};
  overflow: hidden;

  @media screen AND (min-width: ${`${breakpoints.xl}px`}) {
    margin-top: 0;
  }
`;

const AngebotLayout: React.FC<AngebotLayoutProps> = ({ children, styles }) => {
  const [isNavVisible, setIsNavVisible] = useState(false);

  return (
    <Layout styles={styles}>
      {/* <Header title={data.site.siteMetadata.title} />
        <LayoutMain>{children}</LayoutMain> */}
      <Header
        navColor={styles.navColor}
        navColorDesktop={styles.navColorDesktop}
        isNavVisible={isNavVisible}
        setIsNavVisible={setIsNavVisible}
      />
      <Navigation
        isNavVisible={isNavVisible}
        setIsNavVisible={setIsNavVisible}
        navLinkColor={styles.navColor}
      />
      {children}
    </Layout>
  );
};

export default AngebotLayout;
