import React from "react";
import styled from "styled-components";

import { breakpoints } from "../styles/__variables";

interface RoundBGIMGProps {
  image: string;
}

const ImageDiv = styled.div`
  width: 100vw;
  max-height: 70vh;
  object-fit: cover;
  line-height: 0;

  img {
    object-fit: cover;

    width: 100vw;
    max-height: 70vh;
  }
  @media screen and (min-width: ${`${breakpoints.xl}px`}) {
    object-fit: cover;
    max-width: 70vh;
    height: 70vh;
    display: flex;
    flex-direction: column;
    justify-content: center;

    img {
      display: block;
      width: 70vh;
      height: 70vh;
      border-radius: 8000px;
      z-index: 0;
    }
  }
`;

const BGImage: React.FC<RoundBGIMGProps> = ({ image }) => {
  return (
    <ImageDiv className="bgImg">
      <img src={image} />
    </ImageDiv>
  );
};

export default BGImage;
