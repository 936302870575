import React from "react";
import styled from "styled-components";
import Paragraph from "./Paragraph";
import { spacers, colors, breakpoints } from "../styles/__variables";

const ButtonLink = styled.a`
  display: flex;
  text-decoration: none;
  justify-content: center;
  border: 2px solid #eeae2e;
  border-radius: 20px;
  padding: ${spacers.mobile}px;
  margin: ${spacers.mobile}px 0;
  background: ${colors.lightGreen};

  &:hover {
    background: white;
  }

  > div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    margin: ${spacers.mobile}px 0;

    p {
      margin-top: 16px;
    }

    img {
      max-height: 150px;
    }
  }

  @media screen AND (min-width: ${breakpoints.md}px) {

    > div {
      margin: 0;
      text-align: left;
      display: flex;
      flex-direction: column;
      justify-content: center;

      p  {
        margin-top: 0;
      }

      &:first-of-type,
      &:nth-of-type(2) {
        margin-right: ${spacers.mobile}px;
      }

      img {
        height: 100%;
        max-height: 55px;
      }
    }
  }
`;

const FriendlyWorkspaceBlock = () => (
  <ButtonLink href="https://www.perma-lead.com">
    <div>
      <img src="/assets/images/PERMA-Lead_Logo.png" />
    </div>
  </ButtonLink>
);

export default FriendlyWorkspaceBlock;
