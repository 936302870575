import * as React from "react";
import styled from "styled-components";
import Helmet from "react-helmet";
import { colors, breakpoints } from "../styles/__variables";
import Entwickeln from "../components/angebot/Entwickeln";
import Beraten from "../components/angebot/Beraten";
import Anpacken from "../components/angebot/Anpacken";
import Schreiben from "../components/angebot/Schreiben";
import AngebotLayout from "../layouts/Angebot";
import BGImage from "../components/RoundBackgroundImage";
import AngebotWrapper from "../layouts/AngebotWrapper";
import Bow from "../components/Bow";

const styles = {
  navColor: colors.yellowGreen.base,
  navColorDesktop: colors.green.base,
};

const LayoutDiv = styled.div`
  display: flex;
  flex-direction: column;

  > div {
    width: 100%;
    width: 100vw;

    article {
      position: relative;
      z-index: 1;
    }

    &:nth-last-of-type(2n-1) {
      background: ${colors.lightGreen.background};
    }
    &:nth-last-of-type(2n) {
      background: ${colors.yellowGreen.background};
    }
  }

  @media screen AND (min-width: ${`${breakpoints.xl}px`}) {
    height: 300vh;
    overflow-y: hidden;
    width: 100vw;
    overflow: hidden;
    position: relative;
    > div {
      height: 100vh;
    }
  }
`;

const ImgPosDiv1 = styled.section`
  z-index: 1;

  @media screen AND (min-width: ${`${breakpoints.xl}px`}) {
    position: absolute;
    left: -40vh;
    top: 50vh;
  }
`;

const ImgPosDiv2 = styled.section`
  z-index: 1;

  @media screen AND (min-width: ${`${breakpoints.xl}px`}) {
    position: absolute;
    right: -40vh;
    top: 150vh;
  }
`;
const ImgPosDiv3 = styled.section`
  z-index: 1;

  @media screen AND (min-width: ${`${breakpoints.xl}px`}) {
    position: absolute;
    left: -40vh;
    top: 250vh;
  }
`;

const BowPosDiv1 = styled.section`
  z-index: 1;

  @media screen AND (min-width: ${`${breakpoints.xl}px`}) {
    position: absolute;
    right: -30vh;
  }
`;

const BowPosDiv2 = styled.section`
  z-index: 1;

  @media screen AND (min-width: ${`${breakpoints.xl}px`}) {
    position: absolute;
    left: -30vh;
    top: 150vh;
  }
`;

const BowPosDiv3 = styled.section`
  z-index: 1;

  @media screen AND (min-width: ${`${breakpoints.xl}px`}) {
    position: absolute;
    right: -30vh;
    top: 250vh;
    height: 100vh;
  }
`;
const Angebot = () => (
  <AngebotLayout styles={styles}>
    <Helmet
      title="Angebot – Rundum HR"
      link={[
        {
          rel: "shortcut icon",
          type: "image/png",
          href: `./assets/favicon/android-chrome-192x192.png`,
        },
      ]}
    />

    <LayoutDiv>
      <AngebotWrapper>
        <Entwickeln />
      </AngebotWrapper>
      <BowPosDiv1>
        <Bow reversed />
      </BowPosDiv1>
      <ImgPosDiv1>
        <BGImage image="/assets/images/background/photocase_247114.jpg" />
      </ImgPosDiv1>
      <AngebotWrapper>
        <Beraten />
      </AngebotWrapper>
      <BowPosDiv2>
        <Bow />
      </BowPosDiv2>
      <ImgPosDiv2>
        <BGImage image="/assets/images/background/photocase_2340386.jpg" />
      </ImgPosDiv2>
      <AngebotWrapper>
        <Anpacken />
      </AngebotWrapper>
      <BowPosDiv3>
        <Bow reversed />
      </BowPosDiv3>
      {/* <ImgPosDiv3>
        <BGImage image="/assets/images/background/photocase_93238.jpg" />
      </ImgPosDiv3> */}
      {/* <AngebotWrapper>
        <Schreiben />
      </AngebotWrapper> */}
    </LayoutDiv>
  </AngebotLayout>
);

export default Angebot;
