import React from "react";
import Paragraph from "../Paragraph";
import List from "../List";
import Title from "../Title";
import { beratenListe } from "../data";
import { colors } from "../../styles/__variables";
import FriendlyWorkspaceButton from "../FriendlyWorkspaceButton";

const Beraten: React.FC = () => {
  return (
    <article>
      <Title color={colors.green.base}>Beraten</Title>
      <List list={beratenListe} color={colors.green.text} />
      <Paragraph color={colors.green.text}>
        Als HR-Spezialistin mit langjähriger Führungserfahrung berate ich Sie
        rund um HR-Themen. Besondere Erfahrung bringe ich in der Personal- und
        Führungsentwicklung, in der Personalgewinnung und im Betrieblichen
        Gesundheitsmanagement mit. Möchten Sie beispielsweise das BGM-Label
        «Friendly Work Space» erreichen oder Ihren Rekrutierungsprozess unter
        die Lupe nehmen, unterstütze ich Sie dabei. Ich bin für Standortanalysen
        Ihres BGM (inkl. Beratung bei der Vorbereitung zum Label Friendly Work
        Space) von der Gesundheitsförderung Schweiz akkreditiert. Das bedeutet,
        dass Sie finanziell von einem substanziellen Beitrag der
        Gesundheitsförderung Schweiz an Ihr Projekt profitieren können.
      </Paragraph>
      <FriendlyWorkspaceButton />
    </article>
  );
};

export default Beraten;
